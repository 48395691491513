<script lang="ts">
  type LinkInfo = {href: string; title: string; id: string};
  export let links: LinkInfo[];
  import NavLink from './NavLink.svelte';
  import Loading from '../Loading.svelte';
  import {urlOfPath} from '$lib/utils/url';
  import {page, navigating} from '$app/stores';
  import {currentSettingsState} from '$lib/state/settings';
  import {currentGojiraState} from '$lib/state/gojiras';
</script>

<!-- {JSON.stringify($page, null, '  ')} -->

<!-- {JSON.stringify($navigating, null, '  ')} -->

<ul class="flex m-1 border-b border-pink-600">
  {#each links as link}
    <NavLink href={link.href} active={urlOfPath(link.href, $page.url.pathname)}>
      {link.title}
      {#if (link.id === 'gojiras' && ($currentGojiraState.changed || !$currentGojiraState.inList)) || (link.id === 'settings' && ($currentSettingsState.changed || !$currentSettingsState.inList))}
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      {/if}
    </NavLink>
  {/each}
</ul>

{#if $navigating}
  <Loading />
{/if}
